/* for hiding process is not defined bug from console */
body
  > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) {
  display: none;
}
.chart {
  height: 320px !important;
  width: 320px !important;
  justify-items: center;
  margin: auto;
}

.drawer {
  position: fixed;
  z-index: 9999;
  transition: width 0s ease 0.3s, height 0s ease 0.3s,
    transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer > * {
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    box-shadow 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer.drawer-open {
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer .drawer-mask {
  background: #000;
  opacity: 0;
  width: 100%;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    height 0s ease 0.3s;
}
.drawer-content-wrapper {
  position: absolute;
  background: #fff;
}
.drawer-content {
  overflow: auto;
  z-index: 1;
  position: relative;
}
.drawer-handle {
  position: absolute;
  top: 72px;
  width: 41px;
  height: 40px;
  cursor: pointer;
  z-index: 0;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  display: none;
  justify-content: center;
  align-items: center;
  background: #fff;
}
.drawer-handle-icon {
  width: 14px;
  height: 2px;
  background: #333;
  position: relative;
  transition: background 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer-handle-icon:before,
.drawer-handle-icon:after {
  content: "";
  display: block;
  position: absolute;
  background: #333;
  width: 100%;
  height: 2px;
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer-handle-icon:before {
  top: -5px;
}
.drawer-handle-icon:after {
  top: 5px;
}
.drawer-left,
.drawer-right {
  width: 0%;
  height: 100%;
}
.drawer-left .drawer-content-wrapper,
.drawer-right .drawer-content-wrapper,
.drawer-left .drawer-content,
.drawer-right .drawer-content {
  height: 100%;
}
.drawer-left.drawer-open,
.drawer-right.drawer-open {
  width: 100%;
}
.drawer-left.drawer-open.no-mask,
.drawer-right.drawer-open.no-mask {
  width: 0%;
}
.drawer-left {
  top: 0;
  left: 0;
}
.drawer-left .drawer-handle {
  right: -40px;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
  border-radius: 0 4px 4px 0;
}
.drawer-left.drawer-open .drawer-content-wrapper {
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
}
.drawer-right {
  top: 0;
  right: 0;
}
.drawer-right .drawer-content-wrapper {
  right: 0;
}
.drawer-right .drawer-handle {
  left: -40px;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px 0 0 4px;
}
.drawer-right.drawer-open .drawer-content-wrapper {
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
}
.drawer-right.drawer-open.no-mask {
  right: 1px;
  transform: translateX(1px);
}
.drawer-top,
.drawer-bottom {
  width: 100%;
  height: 0%;
}
.drawer-top .drawer-content-wrapper,
.drawer-bottom .drawer-content-wrapper,
.drawer-top .drawer-content,
.drawer-bottom .drawer-content {
  width: 100%;
}
.drawer-top .drawer-content,
.drawer-bottom .drawer-content {
  height: 100%;
}
.drawer-top.drawer-open,
.drawer-bottom.drawer-open {
  height: 100%;
}
.drawer-top.drawer-open.no-mask,
.drawer-bottom.drawer-open.no-mask {
  height: 0%;
}
.drawer-top .drawer-handle,
.drawer-bottom .drawer-handle {
  left: 50%;
  margin-left: -20px;
}
.drawer-top {
  top: 0;
  left: 0;
}
.drawer-top .drawer-handle {
  top: auto;
  bottom: -40px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 0 0 4px 4px;
}
.drawer-top.drawer-open .drawer-content-wrapper {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.drawer-bottom {
  bottom: 0;
  left: 0;
}
.drawer-bottom .drawer-content-wrapper {
  bottom: 0;
}
.drawer-bottom .drawer-handle {
  top: -40px;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px 4px 0 0;
}
.drawer-bottom.drawer-open .drawer-content-wrapper {
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
}
.drawer-bottom.drawer-open.no-mask {
  bottom: 1px;
  transform: translateY(1px);
}
.drawer.drawer-open .drawer-mask {
  opacity: 0.3;
  height: 100%;
  transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer.drawer-open .drawer-handle-icon {
  background: transparent;
}
.drawer.drawer-open .drawer-handle-icon:before {
  transform: translateY(5px) rotate(45deg);
}
.drawer.drawer-open .drawer-handle-icon:after {
  transform: translateY(-5px) rotate(-45deg);
}

/* drawer */
.drawer {
  overflow: visible;
  z-index: 9989 !important;
}
.drawer .drawer-content-wrapper {
  width: 40vw;
  transform: translateX(0px);
}
.drawer .drawer-content-wrapper .drawer-handle {
  display: none !important;
}
@media (min-width: 320px) and (max-width: 767px) {
  .drawer .drawer-content-wrapper {
    width: 100vw;
  }
}

/* modal */
.custom-modal header button {
  display: none;
}

/*input tag css */
.react-tag-input {
  width: 100%;
  background: #f4f5f7;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  padding: 0;
}
.react-tag-input__input {
  font-size: 0.875rem;
  margin: 0;
  height: 3rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  background: transparent;
  color: #4c4f52;
}
.react-tag-input__input:focus {
  background: #ffffff;
}
.react-tag-input__input::placeholder,
.react-tag-input__input:-moz-placeholder,
.react-tag-input__input:-ms-input-placeholder,
.react-tag-input__input::-moz-placeholder,
.react-tag-input__input::-webkit-input-placeholder {
  font-size: 10px;
  font-weight: 400;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.react-tag-input__tag {
  margin: 0.175rem 0.375rem;
  color: #24262d;
}
.theme-dark .react-tag-input {
  color: #ebebeb;
  border-color: #4c4f52;
  background-color: #24262d;
}
.theme-dark .react-tag-input__input {
  color: #ebebeb;
}
.theme-dark .react-tag-input__input:focus {
  background-color: #24262d;
  border-color: #707275;
}

/* dropdown style */
.dropdown {
  display: none !important;
}

/* notification box css */

.notification-box {
  width: 20rem;
  height: 22rem;
}
.notification-content h6 {
  font-size: 13px;
  margin-bottom: 5px;
  -webkit-line-clamp: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.notification-content p span {
  font-size: 11px;
}

.lang-dropdown .fa-globe {
  margin-right: 7px;
  color: var(--heading);
  font-size: 18px;
}

/* country flag css */
.flag {
  width: 25px;
  height: 20px;
  background-image: url("https://raw.githubusercontent.com/bl00mber/react-phone-input-2/master/src/style/common/high-res.png");
  background-repeat: no-repeat;
}
.ad {
  background-position: -48px -24px;
}
.ae {
  background-position: -72px -24px;
}
.af {
  background-position: -96px -24px;
}
.ag {
  background-position: -120px -24px;
}
.ai {
  background-position: -144px -24px;
}
.al {
  background-position: -168px -24px;
}
.am {
  background-position: -192px -24px;
}
.an {
  background-position: -216px -24px;
}
.ao {
  background-position: -240px -24px;
}
.aq {
  background-position: -264px -24px;
}
.ar {
  background-position: -288px -24px;
}
.as {
  background-position: -312px -24px;
}
.at {
  background-position: -336px -24px;
}
.au {
  background-position: -360px -24px;
}
.aw {
  background-position: -384px -24px;
}
.ax {
  background-position: 0px -48px;
}
.az {
  background-position: -24px -48px;
}
.ba {
  background-position: -48px -48px;
}
.bb {
  background-position: -72px -48px;
}
.bd {
  background-position: -96px -48px;
}
.be {
  background-position: -120px -48px;
}
.bf {
  background-position: -144px -48px;
}
.bg {
  background-position: -168px -48px;
}
.bh {
  background-position: -192px -48px;
}
.bi {
  background-position: -216px -48px;
}
.bj {
  background-position: -240px -48px;
}
.bl {
  background-position: -264px -48px;
}
.bm {
  background-position: -288px -48px;
}
.bn {
  background-position: -312px -48px;
}
.bo {
  background-position: -336px -48px;
}
.br {
  background-position: -360px -48px;
}
.bs {
  background-position: -384px -48px;
}
.bt {
  background-position: 0px -72px;
}
.bw {
  background-position: -24px -72px;
}
.by {
  background-position: -48px -72px;
}
.bz {
  background-position: -72px -72px;
}
.ca {
  background-position: -96px -72px;
}
.cc {
  background-position: -120px -72px;
}
.cd {
  background-position: -144px -72px;
}
.cf {
  background-position: -168px -72px;
}
.cg {
  background-position: -192px -72px;
}
.ch {
  background-position: -216px -72px;
}
.ci {
  background-position: -240px -72px;
}
.ck {
  background-position: -264px -72px;
}
.cl {
  background-position: -288px -72px;
}
.cm {
  background-position: -312px -72px;
}
.cn {
  background-position: -336px -72px;
}
.co {
  background-position: -360px -72px;
}
.cr {
  background-position: -384px -72px;
}
.cu {
  background-position: 0px -96px;
}
.cv {
  background-position: -24px -96px;
}
.cw {
  background-position: -48px -96px;
}
.cx {
  background-position: -72px -96px;
}
.cy {
  background-position: -96px -96px;
}
.cz {
  background-position: -120px -96px;
}
.de {
  background-position: -144px -96px;
}
.dj {
  background-position: -168px -96px;
}
.dk {
  background-position: -192px -96px;
}
.dm {
  background-position: -216px -96px;
}
.do {
  background-position: -240px -96px;
}
.dz {
  background-position: -264px -96px;
}
.ec {
  background-position: -288px -96px;
}
.ee {
  background-position: -312px -96px;
}
.eg {
  background-position: -336px -96px;
}
.eh {
  background-position: -360px -96px;
}
.er {
  background-position: -384px -96px;
}
.es {
  background-position: 0px -120px;
}
.et {
  background-position: -24px -120px;
}
.eu {
  background-position: -48px -120px;
}
.fi {
  background-position: -72px -120px;
}
.fj {
  background-position: -96px -120px;
}
.fk {
  background-position: -120px -120px;
}
.fm {
  background-position: -144px -120px;
}
.fo {
  background-position: -168px -120px;
}
.fr {
  background-position: -192px -120px;
}
.ga {
  background-position: -216px -120px;
}
.gb {
  background-position: -240px -120px;
}
.gd {
  background-position: -264px -120px;
}
.ge {
  background-position: -288px -120px;
}
.gg {
  background-position: -312px -120px;
}
.gh {
  background-position: -336px -120px;
}
.gi {
  background-position: -360px -120px;
}
.gl {
  background-position: -384px -120px;
}
.gm {
  background-position: 0px -144px;
}
.gn {
  background-position: -24px -144px;
}
.gq {
  background-position: -48px -144px;
}
.gr {
  background-position: -72px -144px;
}
.gs {
  background-position: -96px -144px;
}
.gt {
  background-position: -120px -144px;
}
.gu {
  background-position: -144px -144px;
}
.gw {
  background-position: -168px -144px;
}
.gy {
  background-position: -192px -144px;
}
.hk {
  background-position: -216px -144px;
}
.hn {
  background-position: -240px -144px;
}
.hr {
  background-position: -264px -144px;
}
.ht {
  background-position: -288px -144px;
}
.hu {
  background-position: -312px -144px;
}
.ic {
  background-position: -336px -144px;
}
.id {
  background-position: -360px -144px;
}
.ie {
  background-position: -384px -144px;
}
.il {
  background-position: 0px -168px;
}
.im {
  background-position: -24px -168px;
}
.in {
  background-position: -48px -168px;
}
.iq {
  background-position: -72px -168px;
}
.ir {
  background-position: -96px -168px;
}
.is {
  background-position: -120px -168px;
}
.it {
  background-position: -144px -168px;
}
.je {
  background-position: -168px -168px;
}
.jm {
  background-position: -192px -168px;
}
.jo {
  background-position: -216px -168px;
}
.jp {
  background-position: -240px -168px;
}
.ke {
  background-position: -264px -168px;
}
.kg {
  background-position: -288px -168px;
}
.kh {
  background-position: -312px -168px;
}
.ki {
  background-position: -336px -168px;
}
.km {
  background-position: -360px -168px;
}
.kn {
  background-position: -384px -168px;
}
.kp {
  background-position: 0px -192px;
}
.kr {
  background-position: -24px -192px;
}
.kw {
  background-position: -48px -192px;
}
.ky {
  background-position: -72px -192px;
}
.kz {
  background-position: -96px -192px;
}
.la {
  background-position: -120px -192px;
}
.lb {
  background-position: -144px -192px;
}
.lc {
  background-position: -168px -192px;
}
.li {
  background-position: -192px -192px;
}
.lk {
  background-position: -216px -192px;
}
.lr {
  background-position: -240px -192px;
}
.ls {
  background-position: -264px -192px;
}
.lt {
  background-position: -288px -192px;
}
.lu {
  background-position: -312px -192px;
}
.lv {
  background-position: -336px -192px;
}
.ly {
  background-position: -360px -192px;
}
.ma {
  background-position: -384px -192px;
}
.mc {
  background-position: 0px -216px;
}
.md {
  background-position: -24px -216px;
}
.me {
  background-position: -48px -216px;
}
.mf {
  background-position: -72px -216px;
}
.mg {
  background-position: -96px -216px;
}
.mh {
  background-position: -120px -216px;
}
.mk {
  background-position: -144px -216px;
}
.ml {
  background-position: -168px -216px;
}
.mm {
  background-position: -192px -216px;
}
.mn {
  background-position: -216px -216px;
}
.mo {
  background-position: -240px -216px;
}
.mp {
  background-position: -264px -216px;
}
.mq {
  background-position: -288px -216px;
}
.mr {
  background-position: -312px -216px;
}
.ms {
  background-position: -336px -216px;
}
.mt {
  background-position: -360px -216px;
}
.mu {
  background-position: -384px -216px;
}
.mv {
  background-position: 0px -240px;
}
.mw {
  background-position: -24px -240px;
}
.mx {
  background-position: -48px -240px;
}
.my {
  background-position: -72px -240px;
}
.mz {
  background-position: -96px -240px;
}
.na {
  background-position: -120px -240px;
}
.nc {
  background-position: -144px -240px;
}
.ne {
  background-position: -168px -240px;
}
.nf {
  background-position: -192px -240px;
}
.ng {
  background-position: -216px -240px;
}
.ni {
  background-position: -240px -240px;
}
.nl {
  background-position: -264px -240px;
}
.no {
  background-position: -288px -240px;
}
.np {
  background-position: -312px -240px;
}
.nr {
  background-position: -336px -240px;
}
.nu {
  background-position: -360px -240px;
}
.nz {
  background-position: -384px -240px;
}
.om {
  background-position: 0px -264px;
}
.pa {
  background-position: -24px -264px;
}
.pe {
  background-position: -48px -264px;
}
.pf {
  background-position: -72px -264px;
}
.pg {
  background-position: -96px -264px;
}
.ph {
  background-position: -120px -264px;
}
.pk {
  background-position: -192px -264px;
}
.pl {
  background-position: -216px -264px;
}
.pn {
  background-position: -240px -264px;
}
.pr {
  background-position: -264px -264px;
}
.ps {
  background-position: -288px -264px;
}
.pt {
  background-position: -312px -264px;
}
.pw {
  background-position: -336px -264px;
}
.py {
  background-position: -360px -264px;
}
.qa {
  background-position: -384px -264px;
}
.ro {
  background-position: 0px -288px;
}
.rs {
  background-position: -24px -288px;
}
.ru {
  background-position: -48px -288px;
}
.rw {
  background-position: -72px -288px;
}
.sa {
  background-position: -96px -288px;
}
.sb {
  background-position: -120px -288px;
}
.sc {
  background-position: -144px -288px;
}
.sd {
  background-position: -168px -288px;
}
.se {
  background-position: -192px -288px;
}
.sg {
  background-position: -216px -288px;
}
.sh {
  background-position: -240px -288px;
}
.si {
  background-position: -264px -288px;
}
.sk {
  background-position: -288px -288px;
}
.sl {
  background-position: -312px -288px;
}
.sm {
  background-position: -336px -288px;
}
.sn {
  background-position: -360px -288px;
}
.so {
  background-position: -384px -288px;
}
.sr {
  background-position: 0px -312px;
}
.ss {
  background-position: -24px -312px;
}
.st {
  background-position: -48px -312px;
}
.sv {
  background-position: -72px -312px;
}
.sy {
  background-position: -96px -312px;
}
.sz {
  background-position: -120px -312px;
}
.tc {
  background-position: -144px -312px;
}
.td {
  background-position: -168px -312px;
}
.tf {
  background-position: -192px -312px;
}
.tg {
  background-position: -216px -312px;
}
.th {
  background-position: -240px -312px;
}
.tj {
  background-position: -264px -312px;
}
.tk {
  background-position: -288px -312px;
}
.tl {
  background-position: -312px -312px;
}
.tm {
  background-position: -336px -312px;
}
.tn {
  background-position: -360px -312px;
}
.to {
  background-position: -384px -312px;
}
.tr {
  background-position: 0px -336px;
}
.tt {
  background-position: -24px -336px;
}
.tv {
  background-position: -48px -336px;
}
.tw {
  background-position: -72px -336px;
}
.tz {
  background-position: -96px -336px;
}
.ua {
  background-position: -120px -336px;
}
.ug {
  background-position: -144px -336px;
}
.us {
  background-position: -168px -336px;
}
.uy {
  background-position: -192px -336px;
}
.uz {
  background-position: -216px -336px;
}
.va {
  background-position: -240px -336px;
}
.vc {
  background-position: -264px -336px;
}
.ve {
  background-position: -288px -336px;
}
.vg {
  background-position: -312px -336px;
}
.vi {
  background-position: -336px -336px;
}
.vn {
  background-position: -360px -336px;
}
.vu {
  background-position: -384px -336px;
}
.wf {
  background-position: 0px -360px;
}
.ws {
  background-position: -24px -360px;
}
.ye {
  background-position: -48px -360px;
}
.za {
  background-position: -96px -360px;
}
.zm {
  background-position: -120px -360px;
}
.zw {
  background-position: -144px -360px;
}

.changeLanguage .dropbtn {
  background-color: transparent;
  /* padding-left: 10px; */
  border: none;
  cursor: pointer;
  /* margin-right: 20px; */
  font-size: 12px;
  color: #162542;
  text-transform: uppercase;
  font-weight: 500;
}
.changeLanguage .dropdown img {
  display: initial;
}

.changeLanguage .dropdown {
  position: relative;
  display: inline-block;
  background-color: transparent;
  margin-top: -5px;
  display: inherit !important;
}

.changeLanguage .dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 140px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 25px;
}
.changeLanguage .dropdown-content button,
.changeLanguage .dropdown-content div {
  padding: 8px 10px;
  text-decoration: none;
  display: block;
  font-size: 12px;
  color: #162542;
  text-transform: uppercase;
  font-weight: 500;
  width: 100%;
  text-align: left;
}
.changeLanguage .dropdown-content button:hover,
.changeLanguage .dropdown-content div:hover {
  background-color: #f2f2f2;
  width: 100%;
}
.changeLanguage .dropdown-content button:hover {
  background-color: #f2f2f2;
  width: 100%;
}

.changeLanguage .dropdown-content a img {
  margin-right: 7px;
}
.changeLanguage .dropdown-content a:hover {
  background-color: #f2f2f2;
}

.changeLanguage .dropdown:hover .dropdown-content {
  display: block;
}

.theme-dark .changeLanguage .dropdown-content {
  background-color: #1a1c23;
  color: #e5e7eb;
}
.theme-dark .changeLanguage .dropdown-content a {
  color: #e5e7eb;
}
.theme-dark .changeLanguage .dropdown-content a:hover {
  background-color: #121317;
}

.theme-dark .changeLanguage .dropbtn {
  color: #e5e7eb;
}

.react-responsive-modal-root {
  z-index: 20001 !important;
}

/* @media (min-width: 1920px) {
  .container {
    max-width: 1920px !important;
  }
} */

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

input[type="file"] {
  display: none;
}

.btn-red {
  background-color: red !important;
}
.btn-orange {
  background-color: orange !important;
}
.btn-gray {
  background-color: #e5e7eb !important;
}

.sun-editor-editable {
  height: calc(100vh - 346px) !important;
  max-height: calc(100vh - 346px);
  overflow-y: auto;
}

.text-ellipsis {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.product-slider-img-alignment {
  width: 400px;
  height: 550px;
  background: #f8f8f8;
  border: 1px solid #f8f8f8;
}

.product-slider-img-alignment .slick-slider {
  width: 100% !important;
  height: 100% !important;
}

.product-slider-img-alignment img {
  height: 550px;
  object-fit: contain;
}
.next-arrow-alignment {
  position: absolute;
  top: 50%;
  right: 10px;
  background: rgba(255, 255, 255, 0.6) !important;
  opacity: 0.61;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.next-arrow-alignment img {
  max-width: 100%;
  height: auto !important;
  border-radius: 0;
}

.previos-arrow-alignment {
  position: absolute;
  top: 50%;
  left: 10px;
  background: rgba(255, 255, 255, 0.6) !important;
  opacity: 0.61;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.previos-arrow-alignment img {
  max-width: 100%;
  height: auto !important;
  border-radius: 0;
}

.heading-width-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 244px;
}
.two-line-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text-o9 {
  font-size: 0.9rem;
}
.slick-arrow {
  display: none !important;
}

.order-list-alignment {
  width: 100%;
  height: 233px;
  border-radius: 10px;
  display: block;
  position: relative;
  min-width: 100%;
}
.order-list-alignment img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  border-radius: 10px;
}

.order-list-alignment::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  top: 0;
  right: 0;
  z-index: 9;
  border-radius: 10px;
}

.order-list-img-details-alignment {
  position: absolute;
  top: 22px;
  left: 22px;
  z-index: 9;
  display: flex;
  align-items: flex-start;
}

.order-list-img-details-left-alignment {
  border-right: 1px solid #cfd2e7;
  padding: 0 20px 0 0;
}

.order-list-profile-img-alignment {
  width: 189px;
  height: 189px;
}

.order-list-profile-img-alignment img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #fff;
}

.order-list-profile-right-side-details-alignment {
  padding: 0 0 0 28px;
  display: flex;
  flex-direction: column;
  gap: 46px;
  height: 100%;
}

.order-list-profile-right-side-details-alignment h4 {
  font-size: 22px;
  font-weight: 700;
  color: #fff;
  padding: 2px 0 8px 0;
  text-align: left;
}

.order-list-profile-right-side-details-alignment p {
  max-width: 216px;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  text-align: left;
}

.order-list-profile-follow-details-section {
  display: flex;
  align-items: center;
}

.order-list-profile-follow-details-alignment {
  text-align: center;
  padding: 0 20px;
  border-right: 1px solid rgba(207, 210, 231, 0.4);
  cursor: pointer;
}

.order-list-profile-follow-details-alignment h6 {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
}

.order-list-profile-follow-details-alignment span {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  display: block;
  padding: 2px 0 0 0;
}

.order-list-profile-follow-details-alignment:first-child {
  padding: 0 20px 0 0;
}

.order-list-profile-follow-details-alignment:last-child {
  padding: 0 0 0 20px;
  border-right: none;
}

.order-list-tab-section {
  border-bottom: 1px solid #868998;
  padding: 0 0 16px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.order-list-tab-icon-alignment {
  width: 100%;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.order-list-tab-icon-width-alignment {
  width: 24px;
  height: 24px;
}

.order-list-tab-icon-width-alignment svg {
  width: 100%;
  height: 100%;
}

.order-list-tab-icon-name-alignment span {
  font-size: 14px;
  font-weight: 600;
  color: #687788;
}

.order-list-bg-alignment {
  margin: 32px 0 0 0;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.order-list-tab-icon-alignment:hover svg path {
  stroke: #143457;
}

.order-list-tab-icon-alignment:hover::before {
  content: "";
  position: absolute;
  bottom: -18px;
  width: 50%;
  height: 4px;
  right: 80px;
  background: #143457;
}

.order-list-tab-icon-alignment:hover span {
  color: #143457;
}

.order-list-tab-icon-alignment.tab-active svg path {
  stroke: #143457;
}

.order-list-tab-icon-alignment.tab-active::before {
  content: "";
  position: absolute;
  bottom: -18px;
  width: 50%;
  height: 4px;
  right: 80px;
  background: #143457;
}

.order-list-tab-icon-alignment.tab-active span {
  color: #143457;
}

.order-list-photos-details-section {
  height: calc(100vh - 500px);
  overflow-y: auto;
}

.order-list-photos-details-section::-webkit-scrollbar {
  width: 4px !important;
}

.order-list-photos-details-section::-webkit-scrollbar-track {
  background: transparent !important;
}

.order-list-photos-details-section::-webkit-scrollbar-thumb {
  background: #404040 !important;
  border-radius: 18px !important;
}

.order-list-photos-grid-alignment {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 22px;
  padding: 16px 5px;
}

.order-list-img-alignment {
  width: 100%;
  height: 292px;
  cursor: pointer;
  border-radius: 20px;
  position: relative;
}

.order-list-img-alignment img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  border-radius: 20px;
}

.order-list-img-alignment video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  border-radius: 20px;
}

.multiple-show-img-icon {
  position: absolute;
  top: 10px;
  right: 19px;
}

.order-list-details-box-alignment {
  border: 1px solid #bad2ec;
  border-radius: 10px;

  margin: 0 0 20px 0;
  cursor: pointer;
}

.order-list-details-section {
  padding: 20px 10px;
  height: calc(100vh - 500px);
  overflow-y: auto;
}

.order-list-details-section::-webkit-scrollbar {
  width: 4px !important;
}

.order-list-details-section::-webkit-scrollbar-track {
  background: transparent !important;
}

.order-list-details-section::-webkit-scrollbar-thumb {
  background: #404040 !important;
  border-radius: 18px !important;
}
.order-list-details-box-alignment:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 5px;
}

.order-list-details-box-alignment:last-child {
  margin: 0;
}

.order-list-details-box-heading-alignment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border-bottom: 1px solid #bad2ec;
}

.order-list-details-box-heading-alignment p {
  font-size: 18px;
  font-weight: 600;
  color: #687788;
  font-size: 16px;
}
.order-list-details-box-details-alignment {
  padding: 12px;
  display: flex;
  align-items: flex-start;
  gap: 18px;
}
.order-list-details-box-details-img-alignment {
  width: 120px;
  height: 120px;
  min-width: 120px;
  max-width: 120px;
}

.order-list-details-box-details-img-alignment img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: contain;
}
.order-list-details-box-all-child-details-alignemt h5 {
  font-size: 20px;
  font-weight: 500;
  color: #091625;
  font-size: 16px;
  padding: 0 0 8px 0;
  text-align: left;
}

.order-list-details-box-all-child-details-alignemt span {
  font-size: 16px;
  font-weight: 500;
  color: #687788;
  font-size: 14px;
  text-align: left;
  display: block;
}

.order-list-details-status-alignment {
  padding: 20px 0 0 0;
  display: flex;
  align-items: center;
  gap: 8px;
}

.order-list-details-status-alignment.shipped p {
  background: #1c67ba;
}

.order-list-details-status-alignment.shipped span {
  color: #1c67ba;
}

.order-list-details-status-alignment.cancle p {
  background: #ff0000;
}

.order-list-details-status-alignment.cancle span {
  color: #ff0000;
}

.order-list-details-status-alignment p {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #07b72e;
}

.order-list-details-status-alignment span {
  font-size: 20px;
  font-weight: 400;
  color: #07b72e;
  font-size: 16px;
}

.order-list-details-cancle-order-alingment button {
  background: transparent;
  border: none;
  font-size: 16px;
  color: #ff0000;
  cursor: pointer;
}

.order-list-no-data-section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.order-list-no-data-section p {
  font-size: 22px;
  font-weight: 700;
  color: #143457;
}

@media only screen and (max-width: 1399px) {
  .order-list-alignment {
    height: 200px;
  }
  .order-list-profile-img-alignment {
    width: 150px;
    height: 150px;
  }

  .order-list-profile-right-side-details-alignment h4 {
    font-size: 20px;
    padding: 2px 0 4px 0;
  }

  .order-list-profile-right-side-details-alignment {
    font-size: 16px;
  }

  .order-list-profile-follow-details-alignment h6 {
    font-size: 18px;
  }

  .order-list-profile-follow-details-alignment span {
    font-size: 14px;
  }
}

@media only screen and (max-width: 992px) {
  .order-list-alignment {
    height: 200px;
  }
  .order-list-profile-img-alignment {
    width: 150px;
    height: 150px;
  }
  .order-list-profile-right-side-details-alignment h4 {
    font-size: 20px;
    padding: 2px 0 4px 0;
  }
  .order-list-profile-right-side-details-alignment {
    font-size: 16px;
  }
  .order-list-profile-follow-details-alignment h6 {
    font-size: 18px;
  }
  .order-list-profile-follow-details-alignment span {
    font-size: 14px;
  }

  .order-list-tab-icon-alignment:hover::before {
    width: 50%;
    bottom: -16px;
    height: 2px;
    right: 24%;
  }
  .order-list-tab-icon-alignment.tab-active::before {
    width: 50%;
    bottom: -16px;
    height: 2px;
    right: 24%;
  }

  .order-list-img-alignment {
    height: 100%;
  }
}

@media only screen and (max-width: 540px) {
  .order-list-alignment {
    height: 152px;
  }
  .order-list-img-details-alignment {
    top: 16px;
    left: 10px;
  }
  .order-list-img-details-left-alignment {
    padding: 0 14px 0 0;
  }
  .order-list-profile-img-alignment {
    width: 70px;
    height: 70px;
  }

  .order-list-profile-right-side-details-alignment {
    padding: 0 0 0 16px;
    gap: 16px;
  }

  .order-list-profile-right-side-details-alignment h4 {
    font-size: 14px;
    line-height: 20px;
  }

  .order-list-profile-right-side-details-alignment p {
    max-width: 100%;
    font-size: 12px;
    margin: 0;
    padding: 0;
    line-height: 18px;
  }

  .order-list-profile-follow-details-alignment {
    padding: 0 16px;
  }

  .order-list-profile-follow-details-alignment:first-child {
    padding: 0 16px 0 0;
  }

  .order-list-profile-follow-details-alignment:last-child {
    padding: 0 0 0 16px;
  }

  .order-list-profile-follow-details-alignment h6 {
    font-size: 12px;
    line-height: 14px;
  }

  .order-list-profile-follow-details-alignment span {
    font-size: 10px;
    line-height: 18px;
  }

  .order-list-bg-alignment {
    margin: 16px 0 0 0;
    padding: 10px;
  }

  .order-list-tab-section {
    padding: 0 0 10px 0;
  }

  .order-list-tab-icon-alignment {
    gap: 6px;
  }

  .order-list-tab-icon-width-alignment {
    width: 16px;
    height: 16px;
  }

  .order-list-tab-icon-name-alignment span {
    font-size: 12px;
  }

  .order-list-tab-icon-alignment:hover::before {
    width: 100%;
    bottom: -12px;
    height: 2px;
    right: 0;
  }
  .order-list-tab-icon-alignment.tab-active::before {
    width: 100%;
    bottom: -12px;
    height: 2px;
    right: 0;
  }

  .order-list-photos-grid-alignment {
    gap: 12px;
  }

  .order-list-img-alignment {
    height: 90px;
  }

  .order-list-photos-details-section {
    height: calc(100vh - 380px);
  }

  .order-list-details-box-details-alignment {
    gap: 6px;
    padding: 8px;
  }

  .order-list-details-box-details-img-alignment {
    width: 60px;
    height: 80px;
    min-width: 60px;
    max-width: 60px;
  }
  .order-list-details-box-all-child-details-alignemt h5 {
    font-size: 14px;
    padding: 0 0 4px 0;
  }

  .order-list-details-box-all-child-details-alignemt span {
    font-size: 12px;
  }

  .order-list-details-status-alignment {
    padding: 14px 0 0 0;
  }

  .order-list-details-cancle-order-alingment button {
    font-size: 12px;
  }

  .order-list-details-box-heading-alignment {
    padding: 8px;
  }

  .order-list-details-box-heading-alignment p {
    font-size: 12px;
  }

  .order-list-details-section {
    height: calc(100vh - 375px);
    padding: 10px 4px;
  }

  .order-list-no-data-section p {
    font-size: 18px;
  }

  .product-slider-img-alignment {
    width: 100%;
    height: 100%;
  }
}

.comment-modal-section .comment-modal-wrapper {
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.comment-modal-box-alignment {
  background: #f8f8f8;
  border-radius: 20px;
  display: flex;
  align-items: center;
  height: calc(100vh - 200px);
}

.comment-header-alignment h6 {
  font-size: 16px;
  color: #212126;
  font-weight: 500;
}
.comment-close-icon {
  width: 14px;
  height: auto;
}
.comment-close-icon img {
  width: 100%;
  height: 100%;
  display: block;
}

.first-div {
  width: 100%;
  max-width: 700px;
  min-width: 500px;
  max-height: calc(100vh - 200px);
  overflow: hidden;
  border-radius: 20px;
}

.comment-modal-box-img-alignment {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.comment-modal-box-img-alignment img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: contain;
  max-height: calc(100vh - 200px);
}

.comment-modal-box-img-alignment video {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: contain;
  max-height: calc(100vh - 200px);
}

.comment-post-design-section {
  background: #f8f8f8;
  border: 1px solid #f8f8f8;
  border-radius: 10px;
  padding: 8px;
  width: 100%;
  display: block;
  margin: 0;
  height: 735px;
  height: calc(100vh - 212px);
}

.comment-post-design-section .slick-slider {
  height: 100% !important;
}
.slick-slider .slick-list {
  height: 100% !important;
}
.slick-list .slick-track {
  height: 100% !important;
}

.comment-post-design-section
  .slick-slider
  .slick-list
  .slick-track
  .slick-slide
  div:first-child {
  height: 100% !important;
}

.comment-post-design-section:hover .previos-arrow-alignment {
  display: flex;
}

.comment-post-design-section:hover .next-arrow-alignment {
  display: flex;
}

.share-product-img-alignment {
  width: 100%;
  height: 354px;
  padding: 0 4px;
}

.share-product-img-alignment img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.share-product-img-alignment video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.share-post-details-alignment {
  padding: 16px 32px 24px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.share-post-heading h5 {
  cursor: pointer;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #1e2432;
}

.share-post-price-alignment {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px 0 0 0;
}
.share-post-price-alignment h6 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #000000;
}

.share-post-price-alignment span {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #808191;
}

.share-post-delivery-shipping {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 0 0 0;
}
.best-seller-alignment h6 {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  background: linear-gradient(263.94deg, #ffc554 7.44%, #744c00 86.5%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.dot-alignment p {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #acb1c0;
}

.discount-menu-align {
  width: auto;
  height: auto;
  background: #ff7d23;
  border-radius: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.discount-menu-align button {
  padding: 1px 9px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #ffffff;

  @media screen and (max-width: 767px) {
    padding: 1px 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #ffffff;
    white-space: nowrap;
  }
}

.delivery-menu-align {
  width: auto;
  height: 21px;
  border-radius: 13.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;

  padding: 4px 8px;
}
.delivery-menu-align img {
  width: 100%;
  height: 100%;
}

.delivery-menu-align span {
  font-weight: 500;
  font-style: italic;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-decoration-line: none;
  color: #5f40af;
}

.arrow-menu-align {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
}
.arrow-menu-align img {
  width: 11px;
  height: 11px;
}

.arrow-menu-align span {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #70c217;
}

.comment-post-other-option-alignment {
  display: flex;
  align-items: center;
  gap: 8px;
}
.comment-post-other-option-alignment .comment-post-box-alignment {
  width: 44px;
  height: 44px;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.comment-post-other-option-alignment .comment-post-box-alignment:hover {
  background: #eef1fc;
}

.comment-post-other-option-alignment .comment-post-box-alignment img {
  width: 24px;
  height: 24px;
  display: block;
}

.slick-arrow {
  display: none;
  transition: 0.15s ease-in-out;
}

.slick-prev {
  left: 10px !important;
  z-index: 99;
}

.slick-prev:hover {
  background: rgba(255, 255, 255, 0.6) !important;
}

.slick-next {
  right: 15px !important;
}
.slick-next:hover {
  background: rgba(255, 255, 255, 0.6) !important;
}

.comment-next-arrow-alignment {
  position: absolute;
  top: 50%;
  right: 10px;
  background: rgba(255, 255, 255, 0.6) !important;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}

.comment-next-arrow-alignment img {
  max-width: 100%;
  height: auto !important;
  border-radius: 0;
}

.comment-previos-arrow-alignment {
  position: absolute;
  top: 50%;
  left: 10px;
  background: rgba(255, 255, 255, 0.6) !important;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}

.comment-previos-arrow-alignment img {
  max-width: 100%;
  height: auto !important;
  border-radius: 0;
}

.comment-modal-details-alignment {
  height: 100%;
  background-color: #fff;
  border-radius: 0 20px 20px 0;
  border-left: 0.5px solid #cfd2e7;
  min-width: 550px;
  max-width: 600px;
}

.comment-modal-main-profile-alignment {
  padding: 15px 20px 10px 20px;
}

.comment-main-profile-flex-alignment {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.comment-modal-main-profile-details-alignment {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 18px;
}

.comment-modal-main-profile-details-alignment .profile-img-alignment {
  width: 50px;
  height: 50px;
  min-width: 50px;
}

.comment-modal-main-profile-details-alignment .profile-img-alignment img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.profile-name-alignment h5 {
  font-size: 14px;
  color: #666;
  font-weight: 400;
  margin: 0;
  text-align: left;
}

.comment-modal-maon-profile-description-alignment {
  display: flex;
  align-items: center;
  gap: 10px;
}

.comment-modal-maon-profile-description-alignment h4 {
  color: #212126;
  font-size: 18px;
  margin: 0;
  font-weight: 600;
  text-align: left;
}

.comment-modal-maon-profile-description-alignment span {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #143457;
  text-align: left;
}

.comment-modal-maon-profile-description-alignment p {
  padding: 4px 0 0 0;
  font-weight: 500;
  color: #868998;
  font-size: 12px;
  margin: 0;
  text-align: left;
}

.use-all-comments-details-alignment {
  padding: 0px 20px 20px 20px;
  height: calc(100vh - 328px);
  overflow-y: auto;
}

.use-all-comments-details-alignment::-webkit-scrollbar {
  width: 0;
}

.comment-cation-alignn {
  display: flex;
  flex-direction: column;
  border-bottom: 0.5px solid #cfd2e7;
  padding: 0px 20px 10px 10px;
}
.comment-cation-alignn div {
  display: flex;
  flex-wrap: wrap;
}
.comment-cation-alignn h6 {
  color: #212126;
  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
  text-align: left;
}

.comment-cation-alignn span {
  padding: 0 3px;
  font-size: 12px;
  font-weight: 400;
  color: #1c67ba;
  line-height: 21px;
  display: block;
  text-align: left;
}

.comment-details-alignment {
  margin: 10px;
}

.comment-details-alignment .main-comment-box-alignment {
  background: rgba(237, 238, 244, 0.4);
  border-radius: 10px;
  padding: 10px;
  width: 500px;
}

.main-comment-box-alignment .comment-profile-alignment {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 14px;
}

.comment-profile-alignment .comment-profile-details-alignment {
  display: flex;
  align-items: flex-start;
  gap: 14px;
}

.comment-profile-details-alignment .comment-profile-img-alignment {
  width: 36px;
  height: 36px;
  max-width: 36px;
  min-width: 36px;
}

.comment-profile-details-alignment .comment-profile-img-alignment img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.comment-profile-desciption-alignment p {
  color: #212126;
  font-size: 12px;
  font-weight: 600;
  margin: 0;
}

.comment-profile-desciption-alignment span {
  font-weight: 600;
}

.comment-profile-option-alignment {
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 4px 0 0 0;
}

.comment-profile-option-alignment span {
  font-size: 12px;
  color: #868998;
  cursor: pointer;
  font-weight: 500;
}

.comment-like-section-alignment {
  width: 16px;
  height: 16px;
  min-width: 16px;
  max-width: 16px;
  cursor: pointer;
}

.comment-like-section-alignment img {
  cursor: pointer;
  max-width: 100%;
}

.comment-like-section-alignment svg {
  width: 100%;
  height: 100%;
}

.view-reply-section {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 40px 0 40px;
}
.view-reply-section .view-reply-line-alignment p {
  height: 1px;
  width: 58px;
  background: #868998;
}

.view-reply-section .view-reply-line-alignment span {
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  color: #868998;
}

.child-comment-box-alignment {
  margin: 10px 0 10px 35px;
  background: rgba(237, 238, 244, 0.4);
  width: 465px;
  padding: 14px;
  border-radius: 10px;
}
.child-comment-box-alignment .comment-profile-alignment {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 14px;
}
.child-comment-box-alignment
  .comment-profile-alignment
  .comment-profile-details-alignment {
  display: flex;
  align-items: flex-start;
  gap: 14px;
}
.comment-profile-details-alignment
  .comment-profile-details-alignment
  .comment-profile-img-alignment {
  width: 36px;
  height: 36px;
  max-width: 36px;
  min-width: 36px;
}
.comment-profile-details-alignment
  .comment-profile-details-alignment
  .comment-profile-img-alignment
  img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.comment-profile-desciption-alignment p {
  color: #212126;
  font-size: 12px;
  margin: 0;
  font-weight: 400;
}
.comment-profile-desciption-alignment span {
  font-weight: 600;
}
.comment-profile-option-alignment {
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 4px 0 0 0;
}

.comment-profile-option-alignment span {
  color: #868998;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
}

.comment-like-section-alignment {
  width: 16px;
  height: 16px;
  min-width: 16px;
  max-width: 16px;
  cursor: pointer;
}
.comment-like-section-alignment img {
  cursor: pointer;
  max-width: 100%;
}

.comment-like-section-alignment svg {
  width: 100%;
  height: 100%;
}

.send-message-input-alignment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 10px 20px 0 20px;
  border-top: 0.5px solid #cfd2e7;
}
.send-message-input-alignment .send-message-input-box-alignment {
  background: rgba(237, 238, 244, 0.4);
  border-radius: 10px;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 0 5px 10px;
}

.react-input-emoji--input {
  font-weight: 500 !important;
  max-height: 40px !important;
  font-family: Poppins, sans-serif !important;
  min-height: 20px;
  outline: none;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  white-space: pre-wrap;
  word-wrap: break-word;
  z-index: 1;
  width: 330px !important;
  user-select: text;
  padding: 10px 15px 11px !important;
  text-align: left;
  justify-content: center;
}

.imogii-icon-alignment {
  cursor: pointer;
  width: 30px;
  height: 30px;
  min-width: 30px;
  max-width: 30px;
}
.imogii-icon-alignment img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

input {
  /* width: 100%; */
  height: 100%;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.333333px;
  border: none;
  background-color: transparent;
}
input::placeholder {
  color: #868998;
}
input:focus {
  outline: none;
}
.send-message-post-butotn-alignment button {
  background: transparent;
  border: none;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.333333px;
  color: #007aff;
  cursor: pointer;
}

.comment-modal-dashbord-details-flex-alignment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px 10px 20px;
  border-top: 0.5px solid #cfd2e7;
}

.comment-modal-dashbord-details-flex-alignment
  .comment-modal-details-first-flex-alignment {
  display: flex;
  align-items: center;
  gap: 10px;
}
.comment-modal-details-first-name-alignment {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  background-color: rgba(224, 19, 19, 0.1);
  padding: 3px 12px;
  border-radius: 20px;
}
.comment-modal-details-first-name-alignment.comment-modal-message-center {
  background-color: rgba(28, 103, 186, 0.1);
}
.comment-modal-details-first-name-alignment.comment-modal-message-center span {
  color: #1c67ba;
}
.comment-modal-details-first-name-alignment.comment-modal-telegram-center {
  background-color: rgba(104, 119, 136, 0.1);
  padding: 5px 9px;
}

.comment-modal-child-icon-details-alignment {
  width: 20px;
  height: 20px;
}
.comment-modal-child-icon-details-alignment img {
  width: 100%;
  height: 100%;
}

.comment-modal-bootmark-alignment {
  border-radius: 20px;
  cursor: pointer;
}

.comment-modal-bootmark-alignment img {
  width: 24px;
  height: 24px;
  display: block;
}
.send-message-input-alignment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 10px 20px 0 20px;
  border-top: 0.5px solid #cfd2e7;
}

.send-message-input-box-alignment {
  background: rgba(237, 238, 244, 0.4);
  border-radius: 10px;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 0 5px 10px;
}

.react-input-emoji--input {
  font-weight: 500 !important;
  max-height: 40px !important;
  font-family: Poppins, sans-serif !important;
  min-height: 20px;
  outline: none;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  white-space: pre-wrap;
  word-wrap: break-word;
  z-index: 1;
  width: 330px !important;
  user-select: text;
  padding: 10px 15px 11px !important;
  text-align: left;
  justify-content: center;
}

.imogii-icon-alignment {
  cursor: pointer;
  width: 30px;
  height: 30px;
  min-width: 30px;
  max-width: 30px;
}
.imogii-icon-alignment img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.imogii-icon-alignment input {
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.333333px;
  border: none;
  background-color: transparent;
}
.imogii-icon-alignment input::placeholder {
  color: #868998;
}

.send-message-post-butotn-alignment button {
  background: transparent;
  border: none;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.333333px;
  color: #007aff;
  cursor: pointer;
}

.comment-header-alignment {
  height: 50px;
  display: none;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  border-bottom: 0.5px solid #cfd2e7;
  position: sticky;
  top: 0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.comment-header-alignment h6 {
  font-size: 16px;
  color: #212126;
  font-weight: 500;
}

.comment-header-alignment .comment-close-icon {
  width: 14px;
  height: auto;
}
.comment-header-alignment .comment-close-icon img {
  width: 100%;
  height: 100%;
  display: block;
}

@media only screen and (max-width: 1024px) {
  .first-div {
    display: none;
  }
  .comment-modal-details-alignment {
    border-radius: 20px;
  }
  .comment-modal-dashbord-details-flex-alignment {
    padding: 12px;
  }
}

@media only screen and (max-width: 992px) {
  .send-message-input-alignment {
    padding: 10px;
  }

  .share-product-img-alignment {
    height: 140px;
  }

  .share-post-details-alignment {
    align-items: end;
    padding: 8px;
  }

  .share-post-delivery-shipping {
    padding: 0;
  }

  .main-comment-box-alignment {
    width: 100% !important;
  }
}

@media only screen and (max-width: 540px) {
  .comment-header-alignment {
    display: flex;
  }
  .comment-modal-box-alignment {
    width: 100%;
    height: 100vh;
    border-radius: 0;
    flex-direction: column;
    align-items: flex-start;
  }
  .first-div {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 200px;
    max-height: 200px;
    overflow: visible;
    display: block;
  }
  .comment-modal-details-alignment {
    min-width: 100%;
    max-width: 100%;
  }
  .comment-modal-main-profile-alignment {
    padding: 15px 20px 0 20px;
  }
  .profile-img-alignment {
    width: 30px !important;
    height: 30px !important;
    min-width: 30px !important;
  }
  .profile-name-alignment h5 {
    font-size: 10px;
  }
  .comment-modal-maon-profile-description-alignment {
    padding: 0;
  }

  .comment-modal-maon-profile-description-alignment h4 {
    font-size: 12px;
  }
  .comment-modal-maon-profile-description-alignment span {
    width: 4px;
    height: 4px;
    margin: 0;
  }
  .comment-modal-maon-profile-description-alignment p {
    font-size: 10px;
    padding: 0;
  }

  .comment-box-icon-alignment img {
    width: 12px;
    height: 12px;
    cursor: pointer;
  }
  .comment-cation-alignn h6 {
    font-size: 14px;
  }
  .comment-cation-alignn span {
    font-size: 10px;
  }
  .use-all-comments-details-alignment {
    padding: 10px;
    height: calc(100vh - 405px) !important;
  }
  .main-comment-box-alignment {
    width: 100% !important;
    padding: 6px !important;
  }

  .comment-profile-img-alignment {
    width: 30px !important;
    height: 30px !important ;
    max-width: 30px !important;
    min-width: 30px !important;
  }

  .comment-profile-desciption-alignment p {
    font-size: 10px;
  }
  .comment-profile-option-alignment {
    gap: 16px;
    padding: 2px 0 0 0;
  }
  .comment-profile-option-alignment span {
    font-size: 10px;
  }
  .view-reply-section {
    padding: 5px 20px;
  }
  .child-comment-box-alignment {
    width: 95%;
    margin: 5px 20px;
    padding: 8px;
  }
  .comment-profile-img-alignment {
    width: 30px;
    height: 30px;
    max-width: 30px;
    min-width: 30px;
  }

  .comment-profile-desciption-alignment p {
    font-size: 10px;
  }

  .comment-profile-option-alignment {
    gap: 16px;
    padding: 2px 0 0 0;
  }
  .comment-profile-option-alignment span {
    font-size: 10px;
  }

  .comment-modal-dashbord-details-flex-alignment {
    padding: 6px 12px;
  }
  .comment-modal-child-icon-details-alignment {
    width: 15px;
    height: 15px;
  }

  .comment-modal-details-first-name-alignment span {
    font-size: 14px;
  }

  .comment-modal-bootmark-alignment svg {
    width: 20px;
    height: 20px;
  }

  .comment-modal-bootmark-alignment img {
    width: 20px;
    height: 20px;
  }

  .send-message-input-alignment {
    gap: 6px;
    padding: 10px;
  }

  .send-message-input-box-alignment {
    padding: 5px 0 5px 5px;
    height: 36px;
    width: 88%;
  }

  .react-input-emoji--input {
    max-height: 28px !important;
    padding: 4px 15px 11px !important;
  }

  .imogii-icon-alignment {
    width: 20px;
    height: 20px;
    min-width: 20px;
    max-width: 20px;
  }

  .send-message-post-butotn-alignment button {
    font-size: 14px;
  }

  .comment-next-arrow-alignment {
    width: 30px;
    height: 30px;
  }

  .comment-previos-arrow-alignment {
    width: 30px;
    height: 30px;
  }

  .comment-post-design-section {
    height: 200px;
  }
}

.variant-img-change-modal .variant-img-modal-wrapper {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
  top: 0;
  right: 0;
}

.variant-img-modal-wrapper .variant-modal-white-box {
  width: 400px;
  height: 300px;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  position: relative;
}

.modal-wrapper {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
  top: 0;
  right: 0;
}

.modal-box-alignment {
  background: #f8f8f8;
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 1000px;
  padding: 20px;
  position: relative;
}

.modal-header-alignment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.modal-header-alignment h3 {
  font-size: 20px;
  color: #212126;
  font-weight: 600;
}

.modal-header-alignment .header-button-alignment {
  display: flex;
  align-items: center;
  gap: 20px;
}

.header-button-alignment .cancle-button-alignment button {
  background: transparent;
  border: none;
  font-size: 16px;
  color: #0e9f6e;
  font-weight: 500;
  cursor: pointer;
}

.header-button-alignment .cancle-button-order button {
  /* background: red; */
  border: none;
  font-size: 16px;
  /* color: #ffffff; */
  font-weight: 500;
  cursor: pointer;

  color: #fff;
  background-color: red;
  cursor: pointer;
  padding: 6px 10px;
  border-radius: 8px;
}

.header-button-alignment .save-button-alignment button {
  border: none;
  font-size: 16px;
  color: #fff;
  background-color: #0e9f6e;
  font-weight: 500;
  cursor: pointer;
  padding: 6px 14px;
  border-radius: 8px;
}

.other-variant-modal-alignment .modal-body-alignment {
  width: 100%;
  padding: 20px 0;
}

.other-variant-modal-alignment .form-alignment {
  text-align: left;
  padding: 0 0 32px 0;
  max-height: calc(100vh - 500px);
  overflow-y: auto;
  overflow-x: hidden;
}

.other-variant-modal-alignment .modal-body-alignment .form-alignmentOrder {
  text-align: left;
  padding: 0 0 10px 0;
  max-height: calc(100vh - 500px);
  overflow-y: auto;
  overflow-x: hidden;
}

.modal-body-alignment .form-alignment:last-child {
  padding: 0;
}

.modal-body-alignment .form-alignment label {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #495057;
  padding: 0 0 0 10px;
  margin: 0;
}

.modal-body-alignment .form-alignmentOrder label {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #495057;
  padding: 0 0 18px 10px;
  margin: 0;
  display: block;
}

.modal-body-alignment .form-alignment .input-details-alignment {
  padding: 16px 0 0 0;
}

.input-details-alignment .input-alignment {
  position: relative;
  margin: 0 0 4px 0;
}
.input-details-alignment .input-alignment.variant-input {
  width: 254px;
}
.input-details-alignment .input-alignment .input-relative {
  position: relative;
}

.input-details-alignment .input-alignment input {
  background: #eceef4;
  border-radius: 10px;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 10px 16px 10px 16px;
  border: none;
}

.input-details-alignment .input-alignment .inputOrder input {
  background: #eceef4;
  border-radius: 10px;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 10px 46px 10px 16px;
  border: none;
}

.input-details-alignment .input-alignment input::placeholder {
  color: #acb1c0;
}

.input-details-alignment .input-alignment .dropdown-alignment {
  background: #ffffff;
  border: 1px solid #e4e4e8;
  box-shadow: 6px 6px 54px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 12px 0;
  position: absolute;
  width: 100%;
  bottom: 54px;
  height: auto;
  max-height: 277px;
  overflow-y: auto;
}
.input-alignment ul {
  list-style: none;
  list-style-position: outside;
  padding: 0;
  margin: 0;
}

.input-alignment ul li {
  padding: 4px 20px;
  margin: 0;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1e2432;
}

.input-alignment ul li:hover {
  cursor: pointer;
  background: #eceef4;
  box-shadow: 6px 6px 54px rgba(0, 0, 0, 0.05);
}

.modal-body-alignment .form-alignment .option-label {
}

.delete-icon-alignment {
  position: absolute;
  top: 10px;
  right: 10px;
  display: block;
}

.delete-icon-alignment img {
  width: 24px;
  height: 24px;
  display: block;
  cursor: pointer;
}

.z-99999 {
  z-index: 9999;
}

.no-comment-data-alignment {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 4px;
}
.no-comment-data-alignment span {
  font-size: 20px;
  color: #000000;
}

.comment-other-option-alignment {
  display: flex;
  align-items: center;
  gap: 10px;
}

.height-calc-modal {
  height: calc(100vh - 200px);
  overflow: auto;
  overflow-y: auto  ;
}

.active-link {
  color: rgba(229, 231, 235, 1);
}

.lowercase-placeholder::placeholder {
  text-transform: none;
}

.dark-calander {
  color-scheme: dark;
}


.input-details-alignment .input-alignment.variant-input p {
  padding: 0 0 10px 0;
} 


.form-alignmentOrder p {
  padding: 0 0 10px 0;

}